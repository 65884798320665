import React from 'react';

function HesapSil() {
  return (
    <>
      <div className="bred_crumb blog_detail_bredcrumb">
        <div className="container">
          <div className="bred_text">
            <h1>Hesap Silme Talebi</h1>
            <ul>
              <li><a href="../">Anasayfa</a></li>
              <li><span>»</span></li>
              <li><a href="">Hesap Silme Talebi</a></li> {/* Replace with valid href */}
            </ul>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <section className="blog_detail_section">
        <div className="container">
          <section className="contact_page_section" id="contact">
            <div className="container">
              <div className="contact_inner" style={{ display: 'block' }}>
                <div className="contact_form" style={{ width: '100% !important' }}>
                  <form action="">
                    <div className="form-group">
                      <input type="text" placeholder="Telefon Numaranız" className="form-control" />
                    </div>
                    <div className="form-group">
                      <input type="email" placeholder="E-Posta Adresiniz" className="form-control" />
                    </div>
                    <div className="form-group">
                      <select className="form-control">
                        <option value="">İşlem Yapmak İstediğiniz Uygulama</option>
                        <option value="ways-agency">Ways - Agency</option>
                        <option value="ways-driver">Ways - Driver</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <select className="form-control">
                        <option value="">Size hangi kanaldan dönüş yapalım?</option>
                        <option value="email">E-Posta</option>
                        <option value="phone">Cep Telefonu</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <textarea className="form-control" placeholder="Hesap Silme Talebinizin Sebebi"></textarea>
                    </div>
                    <div className="form-group term_check">
                      <input type="checkbox" id="term" />
                      <label htmlFor="term">Hesabıma ait bilgilerin getirilemeyeceğini ve hesap bilgilerime bir daha erişemeyeceğimi anlıyorum</label>
                    </div>
                    <div className="form-group mb-0">
                      <button type="submit" className="btn puprple_btn">Talep Gönder</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default HesapSil;
