import React from 'react';
import { Link } from 'react-router-dom';
import blogDetailMainImage from '../images/blog_detail_main.png';  // Adjust the path as necessary


function VeriGuvenligiPolitikasi() {
  return (
    <div>
      <div
        className="bred_crumb blog_detail_bredcrumb"
 
      >
        <div className="container">
          <div className="bred_text">
            <h1>Veri Güvenliği Politikası</h1>
            <ul>
              <li><Link to="/">Anasayfa</Link></li>
              <li><span>»</span></li>
              <li><Link to="">Veri Güvenliği Politikası</Link></li>
            </ul>
          </div>
        </div>
      </div>

      <section className="blog_detail_section">
        <div className="container">
          <div className="blog_inner_pannel">
            <div className="main_img">
              <img src={blogDetailMainImage} alt="image" />
            </div>
            <div className="info">
              <p>6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU HAKKINDA AYDINLATMA METNİ VE GİZLİLİK POLİTİKASI BÖLÜM I) 6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU HAKKINDA AYDINLATMA METNİ VE GİZLİLİK POLİTİKASI</p>

              <h2>Veri Sorumlusu</h2>
              <p><b>1.1)</b> 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) çerçevesinde, Ways adlı işletme (bundan böyle "Site Sahibi" veya "Şirket" olarak anılacaktır), kişisel verilerin işlenmesi konusunda asli sorumlu tutulmaktadır. Bu çerçevede, kişisel verilerinizi, KVKK kapsamında belirtilen usul ve esaslara uygun bir şekilde işleyebilecektir. İşbu işleme faaliyeti, Şirket tarafından aşağıda detaylandırılan hususlar doğrultusunda gerçekleştirilecektir.</p>

              <p><b>1.2)</b> Bu Gizlilik Politikası ve KVKK Aydınlatma Metni, Ways TEKNOLOJİ tarafından işletilen www.Ways.com internet sitesi ve bu platforma ait Mobil uygulamanın yönetimi sırasında, Site veya Mobil uygulama kullanıcıları, üyeleri ve ziyaretçileri (bundan böyle topluca "Veri Sahibi" olarak anılacaktır) tarafından Şirket ile paylaşılan veya Veri Sahibi'nin Site veya Mobil uygulamanın kullanımı esnasında ortaya çıkan kişisel verilerin kullanılmasına ilişkin şartlar ve koşullar açıkça belirtilmiştir.</p>

              <h2>2)Kişisel Veriler Hangi Amaçla İşlenmektedir?</h2>
              <p><b>2.1)</b> Ways ile iş ilişkisi bulunan kişilere ait toplanan kişisel veriler, şirketin hukuki ve ticari güvenliğini sağlamak, ticari ve iş stratejilerini belirlemek ve uygulamak gibi amaçlarla işlenecektir. Bu işleme faaliyetleri, KVKK'nın 5. ve 6. maddelerinde açıklanan kişisel veri işleme şartları ve amaçlarına uygun olarak gerçekleştirilecektir. Söz konusu kişisel veriler, iş ortakları, hissedarlar, yasal olarak yetkili kamu kurumları ve özel kişilere, KVKK'nın 8. ve 9. maddelerinde tanımlanan kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilir.</p>
              <p><b>2.2)</b> Kişisel veriler, yukarıda belirtilen amaçlar dışında kullanılmayacak ve yasal yükümlülükler ile resmi kurum veya kuruluşlar haricinde üçüncü taraflarla paylaşılmayacaktır. Kişisel verileriniz, sadece 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayımlanarak yürürlüğe giren 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında belirtilen şartlar ve amaçlar dahilinde işlenebilir ve aktarılabilir. Bu süreç, ilgili kanunun öngördüğü çerçevede titizlikle yürütülecektir.</p>

              <h2>3)Kişisel Veri nedir?</h2>
              <p><b>3.1)</b> Ways tarafından işlenen kişisel veriler, Kişisel Verilerin Korunması Kanunu'nda belirtilen esaslara uygun bir biçimde tanımlanmıştır. "Kişisel veri" terimi, ancak sınırlı olmamak üzere aşağıda belirtilen bilgi türlerini içermektedir:<br /><br />
                Kimlik ve İletişim Bilgisi: Bu kategori, bireyin kimliğini ve iletişim kurulmasını sağlayan bilgileri içerir. Örnek olarak ad, soyad, telefon numarası, adres, iş yeri bilgileri, e-posta adresi gibi bilgiler yer alır. <br /><br />
                Kullanıcı Bilgisi, Kullanıcı İşlem Bilgisi ve Finansal Bilgi: Bu bilgiler, kullanıcıların üyelik ayrıntıları, üyelik ID numarası, Site Sahibi'nin hizmetlerinden yararlanma tarihleri ve saatleri, Site Sahibi veya yardım hizmetleriyle iletişime geçme sebepleri, sitede yapılan aramalarda kullanılan terimler, kullanıcı yorumları, kullanım sırasında oluşan hatalar, fatura ve ödeme bilgileri, bakiye bilgileri (faturalar, ödeme dekontları, fatura numarası, tutarı ve kesim tarihi gibi) gibi bilgileri kapsar. <br /><br />
                Lokasyon Bilgisi: Sürücü uygulaması, arka planda çalışırken dahi kullanıcının mevcut konum bilgilerini toplar. Bu bilgiler, teslimatı sipariş eden müşteri ile paylaşılabilir ve ilgili kanun kapsamında gereken en kısa süre boyunca saklanır. <br /><br />
                İşlem Güvenliği Bilgisi: Bu kategori, kullanıcıların güvenlik bilgilerini içerir; örneğin, giriş bilgileri, parolalar, uygulama kullanım sıklığına ilişkin raporlar ve değerlendirmeler, hedefleme bilgileri, çerez kayıtları gibi. <br /><br />
                Talep/Şikayet Yönetimi Bilgisi: Site üzerinden yapılan talep ve şikayetler bu kategoriye dahildir.
                Risk Yönetimi Bilgisi: Örneğin IP adresi gibi veriler, bu kategori altında değerlendirilir.</p>
              <p><b>3.2)</b> Kişisel Verilerin Korunması Kanunu'nun 3. ve 7. maddeleri uyarınca, anonim hale getirilen veriler kişisel veri olarak kabul edilmeyecektir. Bu tür veriler üzerindeki işleme faaliyetleri, bu Gizlilik Politikası'nın hükümleri dışında gerçekleştirilecektir. Verilerin işlenip işlenmediği konusunda bilgi almak için, bu belgenin II. Bölümüne başvurulabilir. Bu bölüm, verilerin işlenme durumu hakkında soruların yanıtlanması için tasarlanmıştır.</p>

              <h2>4)Verilerinizin Kullanım Amacı Nedir?</h2>
              <p><b>4.1)</b> Ways ile paylaşılan kişisel verilerin kullanım amacı, kullanıcıların Site veya Mobil uygulama üzerinden sunulan hizmetlere erişimlerini ve bu hizmetlerden faydalanmalarını mümkün kılmaktır. Bu amaçlar aşağıdaki hususları içermektedir: <br /><br />
                Site üzerindeki üyelik işlemlerinin gerçekleştirilmesi ve bu kayıtların güncellenmesi, <br /><br />
                Site Sahibi tarafından sunulan hizmetlerin ve Site üzerinden sunulan hizmetlerin iyileştirilmesi, yeni hizmetlerin geliştirilip tanıtılması ve bu bağlamda kullanıcılara gerekli bilgilendirmelerin yapılması, <br /><br />
                Ticari faaliyetlerin etkin bir şekilde yürütülmesi, ticari ve iş stratejilerinin belirlenip uygulanması, <br /><br />
                Site Sahibi ve iş ilişkisi içinde olan kişilerin hukuki ve ticari güvenliğinin sağlanması ve bu kapsamda gerekli bilgilendirmelerin yapılması, <br /><br />
                Bu faaliyetlerin doğasından kaynaklanan yükümlülüklerin yerine getirilmesi.</p>
              <p><b>4.2)</b> Kişisel verilerin toplanmasının birincil amacı, kullanıcılarla etkili bir iletişim kurmak ve mobil uygulama deneyimlerini iyileştirmektir. Bu kapsamda, iletişim yönetimi, müşteri memnuniyeti araştırmaları, şirket içi raporlama ve iş geliştirme faaliyetleri için kullanılmaktadır. Ayrıca, kişisel kimlik bilgileri ifşa edilmeden, anonim veriler üzerinden istatistiksel değerlendirmeler yapma, veri tabanı oluşturma ve pazar araştırmaları yapma gibi amaçlar da bulunmaktadır.</p>
              <p><b>4.3)</b> KVKK'nın 5. ve 8. maddeleri uyarınca, bazı durumlarda kişisel veriler, Veri Sahiplerinin açık rızası olmadan işlenebilir ve üçüncü kişilerle paylaşılabilir. Bu durumlar şunları içerir:</p>
              <ul className="listings">
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> Kanunlarda açıkça öngörülmesi,</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> Rıza gösterme imkânı olmayan veya rızasının hukuki geçerliliği bulunmayan kişilerin hayatını veya beden bütünlüğünü koruma gerekliliği,</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> Sözleşmenin kurulması veya ifası ile doğrudan ilişkili olduğunda kişisel verilerin işlenmesinin zorunlu olması,</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> Site Sahibinin hukuki yükümlülüklerini yerine getirmek için zorunlu olması,</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> Veri Sahibi tarafından alenileştirilen veriler,</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> Bir hakkın tesisi, kullanılması veya korunması için zorunlu olması,</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> Veri Sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Site Sahibinin meşru menfaatleri için gerekli olması.</p>
                </li>
              </ul>
              <p><b>4.4)</b> Site Sahibi, veri toplama sürecinde çerez (cookie) kullanımına başvurabilir. Bu çerezler, kullanıcıların site deneyimlerini iyileştirmek ve üçüncü kişiler tarafından sunulan analiz hizmetlerine veri sağlamak amacıyla işlenebilir. Bu veriler, analiz hizmetlerinin gereksinimleri doğrultusunda ve sadece gerekli ölçüde üçüncü kişilere iletilir. Bu konuda daha detaylı bilgi için belgenin III. Bölümü olan Çerez Politikası'na bakılabilir.</p>

              <h2>5)İşlenen Veriler Kimlere Hangi Amaç ile Aktarılabilecektir?</h2>
              <p><b>5.1)</b> Toplanan kişisel veriler, kullanıcı deneyiminin geliştirilmesi, güvenliğin sağlanması, hileli veya izinsiz kullanımların tespiti, araştırma faaliyetleri, hataların giderilmesi ve Gizlilik Politikası'nda belirtilen diğer amaçlar için işlenebilir. Bu veriler, şu amaçlarla sınırlı olmak üzere, dış kaynak hizmet sağlayıcıları (SMS ve e-posta gönderimi sağlayan hizmetler gibi), hosting servisleri ve hukuk büroları gibi üçüncü kişilere aktarılabilir. Bu aktarımlar, ürün ve hizmetlerin kullanıcı beğeni ve ihtiyaçlarına uygun hale getirilmesi, şirketin ve bağlı şirketlerin hukuki ve ticari güvenliğinin sağlanması, yönetimsel operasyonlar, fiziksel güvenlik ve denetim, değerlendirme süreçleri, itibar araştırmaları, hukuki uyum, denetim, mali işler ve insan kaynakları politikalarının yürütülmesi gibi amaçlarla gerçekleştirilir. Bunlar, KVKK'nın 8. ve 9. maddelerinde belirtilen şartlar ve amaçlar çerçevesinde yapılır.</p>
              <p><b>5.2)</b> Veri Sahibi, bu belirtilen amaçlarla sınırlı olarak, üçüncü tarafların kişisel verileri dünya çapında bulunan sunucularda saklayabileceğini ve bu duruma önceden onay verdiğini kabul eder.</p>
              <p><b>5.3)</b> Veri Sahibi, Gizlilik Politikası'nda bahsedilen bilgilerin tam, doğru ve güncel olduğunu taahhüt eder ve bu bilgilerde herhangi bir değişiklik olduğunda derhal güncelleme yapılacağını kabul eder. Güncel olmayan bilgiler nedeniyle Site Sahibinin herhangi bir sorumluluğu olmayacaktır.</p>
              <p><b>5.4)</b> Veri Sahibi, kişisel verilerinin Site Sahibi tarafından kullanılmamasını talep etmesi durumunda, Site veya Mobil uygulamanın hizmetlerinden tam olarak faydalanamayabileceğini kabul eder ve bu durumdan doğacak tüm sorumluluğu üstlenir.</p>

              <h2>6) Verilerinizi Ne Kadar Süreyle Saklanır?</h2>
              <p>Paylaşılan kişisel veriler, Gizlilik Politikası'nda belirtilen amaçların gerektirdiği süre ve ilgili mevzuatta belirtilen zamanaşımı süreleri boyunca saklanır. Ayrıca, olası uyuşmazlık durumlarında, uyuşmazlığa ilişkin savunmaların yapılabilmesi amacıyla, bu veriler sınırlı bir süre daha saklanabilir.</p>

              <h2>7)Verilerinizin Güvenliğini Nasıl Sağlıyoruz?</h2>
              <p><b>7.1)</b> Site Sahibi, kişisel verilerin hukuka aykırı olarak işlenmemesi, kişisel verilere hukuka aykırı olarak erişilmemesi ve bu verilerin korunması için gerekli teknik ve idari tedbirleri almakta ve bu alandaki denetimleri gerçekleştirmektedir. Bu önlemler, ilgili mevzuatta belirlenen şartlar ve işbu metinde ifade edilen koşullar dikkate alınarak, maliyetler gözetilerek ve asgari güvenlik düzeyini sağlamak amacıyla alınmaktadır. Ancak, Site ve sisteme yönelik saldırılar sonucu gizli bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda, Site Sahibi'nin sorumluluğu bulunmayacaktır.</p>
              <p><b>7.2)</b> Ayrıca, Site Sahibi, elde ettiği kişisel verileri, Gizlilik Politikası ve Kişisel Verilerin Korunması Kanunu hükümlerine uygun olarak korumakta, bu bilgileri işleme amacı dışında kullanmamakta veya başkalarına açıklamamaktadır.</p>
              <p><b>7.3)</b> Site üzerinden başka uygulamalara verilen linkler söz konusu olduğunda, Site Sahibi bu uygulamaların gizlilik politikaları ve içerikleri konusunda sorumluluk taşımamakta ve kullanıcıların bu metinleri incelemelerini önermektedir.</p>

              <h2>8)Gizlilik Politikası’ndaki Değişiklikler</h2>
              <p>İşbu Gizlilik Politikası, değişen şartlara ve mevzuata uyum sağlamak amacıyla zaman zaman güncellenebilir.</p>

              <h2>BÖLÜM II) 6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA BAŞVURU HAKKINIZ:</h2>
              <p>Bu bölümde, Kişisel verilerinizin işlenmesiyle ilgili olarak; kişisel verilerin toplanması, işlenme amacı ve bu verilerin aktarılacağı kişiler hakkında bilgi verilmiştir. KVKK kapsamında, aşağıda sıralanan haklara sahipsiniz:</p>
              <ul className="listings">
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> a.Kişisel verilerinizin işlenip işlenmediğini öğrenme hakkı.</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> b.İşlenmiş kişisel veriler hakkında bilgi talep etme hakkı.</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> c.Kişisel verilerin işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme hakkı.</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> d.Kişisel verilerin işlenmesini gerektiren sebepler ortadan kalktığında, bu verilerin silinmesi veya yok edilmesi hakkı.</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> e.Kişisel verilerin kanuna aykırı işlenmesi sebebiyle zarara uğranıldığında, zararın giderilmesini talep etme hakkı.</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> f.Kişisel verilerin eksik veya yanlış işlenmesi durumunda, bu verilerin düzeltilmesi hakkı.</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> g.Düzeltilen veya silinen kişisel verilerin, aktarıldığı üçüncü kişilere bildirilmesini isteme hakkı.</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> h.İşlenen verilerin otomatik sistemlerle analiz edilerek kişi aleyhine bir sonuç doğurmasına itiraz etme hakkı.</p>
                </li>
                <li>
                  <p><span className="icon"><i className="icofont-check-circled"></i></span> ı.Kişisel verilerin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme hakkı.</p>
                </li>
              </ul>
              <p>Veri Sahibi olarak, tüm bu konularda, Veri Sorumlusu Şirket olan Ways'e başvurabilirsiniz. Talep üzerine, hukuken korunması gerekmeyen bilgiler, veri kayıt sisteminden silinebilir, yok edilebilir veya anonim hale getirilebilir, ancak kanuni haklar saklı tutulur.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VeriGuvenligiPolitikasi;
