import React from 'react';
import { Link } from 'react-router-dom';
import bannerShape1 from '../images/banner-shape1.png'; // Adjust the path as needed
import bannerShape2 from '../images/banner-shape2.png'; // Adjust the path as needed
import bannerShape3 from '../images/banner-shape3.png'; // Adjust the path as needed
import goTopIcon from '../images/go_top.png'; // Adjust the path as needed

function Footer() {
  return (
    <footer>
      <div className="newsletter_section">
        <div className="top_footer" id="contact">
          <div className="container">
            <div className="row">
              <span className="banner_shape1"><img src={bannerShape1} alt="image" /></span>
              <span className="banner_shape2"><img src={bannerShape2} alt="image" /></span>
              <span className="banner_shape3"><img src={bannerShape3} alt="image" /></span>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="abt_side">
                  <ul>
                    <li><a href="mailto:info@waysapp.com.tr">info@waysapp.com.tr</a></li>
                    <li><a href="tel:+905427413489">+90 542 741 34 89</a></li>
                    <li><a href="tel:+905428413489">+90 542 841 34 89</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="links">
                  <ul>
                    <li><Link to="/cerezler-politikasi">Çerezler Politikası</Link></li>
                    <li><Link to="/gizlilik-politikasi">Gizlilik Politikası</Link></li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="links">
                  <ul>
                    <li><Link to="/kullanici-sozlesmesi">Kullanıcı Sözleşmesi</Link></li>
                    <li><Link to="/veri-guvenligi-politikasi">Veri Güvenliği Politikası</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>© Copyrights 2023. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="go_top">
          <span><img src={goTopIcon} alt="Go to top" /></span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
