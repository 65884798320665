import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import CerezlerPolitikasi from './pages/CerezlerPolitikasi';
import GizlilikPolitikasi from './pages/GizlilikPolitikasi';
import HesapSil from './pages/HesapSil';
import KullaniciSozlesmesi from './pages/KullaniciSozlesmesi';
import VeriGuvenligiPolitikasi from './pages/VeriGuvenligiPolitikasi';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cerezler-politikasi" element={<CerezlerPolitikasi />} />
        <Route path="/gizlilik-politikasi" element={<GizlilikPolitikasi />} />
        <Route path="/hesap-sil" element={<HesapSil />} />
        <Route path="/kullanici-sozlesmesi" element={<KullaniciSozlesmesi />} />
        <Route path="/veri-guvenligi-politikasi" element={<VeriGuvenligiPolitikasi />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
