import React from 'react';
import { Link } from 'react-router-dom';
import blogDetailMainImage from '../images/blog_detail_main.png';  
 

function KullaniciSozlesmesi() {
  return (
    <div>
      <div
        className="bred_crumb blog_detail_bredcrumb"
    
      >
        <div className="container">
          <div className="bred_text">
            <h1>Kullanıcı Sözleşmesi</h1>
            <ul>
              <li><Link to="/">Anasayfa</Link></li>
              <li><span>»</span></li>
              <li><Link to="">Kullanıcı Sözleşmesi</Link></li>
            </ul>
          </div>
        </div>
      </div>

      <section className="blog_detail_section">
        <div className="container">
          <div className="blog_inner_pannel">
            <div className="main_img">
              <img src={blogDetailMainImage} alt="image" />
            </div>
            <div className="info">
              <h2>TARAFLAR</h2>
              <p>Bu sözleşme, Ways'in işlettiği web sitesi ve mobil uygulama üzerinden platforma üye olan kullanıcılar arasında, kullanıcının üyeliği onaylandığında düzenlenir. Sözleşmede, platform "WAYS", kullanıcı ise "KULLANICI" olarak adlandırılır. Üye olmak, Sözleşme'nin tüm şartlarını okuduğunuz, anladığınız ve kabul ettiğiniz anlamına gelir.</p>
              <h2>TANIMLAR</h2>
              <h3>TANIM A</h3>
              <p><b>A.1. KULLANICI:</b> Ways'in işlettiği web sitesi ve mobil uygulama üzerinden platforma erişim sağlayan, üyelik işlemlerini tamamlayarak bu platformdaki hizmetleri kullanmaya yetkili olan herhangi bir gerçek ya da tüzel kişi.</p>
              <p><b>A.2. SÜRÜCÜ:</b> Taşımacılık hizmeti veren ve Ways platformunda kayıtlı olan, bu platform aracılığıyla KULLANICI'lara ulaşan ve onlara hizmet sunan herhangi bir gerçek veya tüzel kişi.</p>
              <p><b>A.3. PLATFORM:</b> Kullanıcı ve Sürücü'nün www.waysapp.com.tr web sitesi ve Ways mobil uygulaması aracılığıyla bir araya geldikleri, hizmet alışverişinin gerçekleştiği dijital altyapı ve sanal ortam.</p>
              <p><b>A.4. SİTE:</b> www.waysapp.com.tr internet adresi ve bu ana siteye bağlı tüm alt alanlar, sayfalar ve içerikler.</p>
              <h3>TANIM B: KİŞİSEL VERİLER</h3>
              <p><b>B.1.</b> Kullanıcı, Ways'in belirlenen hizmetler ve kampanyalar hakkında bilgilendirme yapmak amacıyla SMS mesajları gönderme hakkına sahip olduğunu kabul eder.</p>
              <p><b>B.2.</b> Kullanıcı, üyelik sürecinde ve platform kullanımı esnasında verdiği belirli kişisel bilgilerin Ways tarafından işlenmesine ve saklanmasına rıza gösterir. Ways, bu bilgileri üçüncü şahıslarla, özellikle Sürücüler dışında kalan diğer taraflarla paylaşmama konusunda taahhütte bulunur.</p>
              <h3>TANIM C: FİKRİ MÜLKİYET HAKLARI</h3>
              <p><b>C.1.</b> Ways platformunun tüm fikri ve sınai mülkiyet hakları (marka, logo, yazılım, tasarım, içerik vb.) tamamen Ways'e aittir.</p>
              <p><b>C.2.</b> Platformun içeriğinin, özellikle yazılım, tasarım, metin, grafik, logo, video ve benzeri materyallerinin, Ways'in yazılı izni olmadan herhangi bir şekilde çoğaltılması, değiştirilmesi, yayılması veya ticari amaçla kullanılması yasaktır.</p>
              <h3>TANIM D: TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ</h3>
              <p><b>D.1.</b> Kullanıcı, platforma üye olmakla birlikte, bu Sözleşme'nin ve platformun kullanım koşullarının tümünü eksiksiz olarak yerine getirme yükümlülüğünü kabul eder.</p>
              <p><b>D.2.</b> Kullanıcı, kendisine ait hesap bilgilerinin güvenliğinden tamamen sorumlu olduğunu kabul eder ve bu bilgilerin yetkisiz kullanımından doğabilecek zararlardan Ways'in sorumlu olmadığını anlar.</p>
              <p><b>D.3.</b> Platform üzerinden yasaklanmış veya değeri yüksek olan eşyaların taşınmasının kabul edilmeyeceğini ve bu tür gönderilerin yapılmaması gerektiğini Kullanıcı kabul eder.</p>
              <p><b>D.4.</b> Ways platform kullanımında bu Sözleşme'ye veya platformun kurallarına aykırı davranışları tespit ettiğinde, ilgili kullanıcının üyeliğini sonlandırma hakkını saklı tutar.</p>
              <p><b>D.5.</b> Kullanıcı, Ways'in web ve mobil uygulamaları aracılığıyla yasal olmayan herhangi bir ürün veya ürünlerin gönderimini yapması durumunda, bu eylemden doğacak her türlü yasal sorumluluğu kabul eder. Ways, bu tür yasa dışı gönderilerden kaynaklanan herhangi bir yasal sorumluluğu kabul etmez.</p>
              <p><b>D.6.</b> Teknik aksaklıklar, sistem bakımı veya dış faktörler nedeniyle oluşabilecek erişim kesintilerinden Ways'in sorumlu olmadığını Kullanıcı kabul eder.</p>
              <p><b>D.7.</b> Ways, bu Sözleşme'nin içeriğini ve şartlarını herhangi bir zamanda, önceden bildirimde bulunmaksızın değiştirme hakkına sahiptir.</p>
              <h3>TANIM E: HİZMET KULLANIM ŞARTLARI</h3>
              <p><b>E.1.</b> Web sitesi ve mobil uygulamaya üye olabilmek için, kullanıcının 18 yaşını doldurmuş olması ve Ways tarafından daha önce geçici ya da süresiz olarak platform kullanımından men edilmemiş olması gerekir.</p>
              <p><b>E.2.</b> Kullanıcı, üyelik işlemi sırasında gerekli bilgileri eksiksiz ve doğru bir şekilde doldurur ve GSM numarasına gelen aktivasyon kodunu kullanarak kaydını tamamlar.</p>
              <p><b>E.3.</b> Ways tarafından geçici ya da süresiz olarak üyelikten men edilmemiş olmak, web sitesine ve mobil uygulamaya üye olabilmek için gereklidir.</p>
              <p><b>E.4.</b> Kullanıcı, Platform üzerinden sürücü talep ettiğinde, talep edilen hizmete uygun, yakın konumdaki uygun sürücüler’e talep bilgisi verilir.</p>
              <p><b>E.5.</b> Kullanıcı, çağrılan sürücü'nün adrese ulaşmasını müteakip, gönderiyi teslime hazır bir şekilde bulundurmayı taahhüt eder.</p>
              <p><b>E.6.</b> Kullanıcı, Platform üzerinden çağırdığı sürücü'ye hizmet bedelini platformun belirlediği ödeme yöntemleriyle veya nakit olarak ödeyeceğini kabul eder.</p>
              <p><b>E.7.</b> Ways, ödemelerin gerçekleşmesi, gönderilerin kaybolması veya hasar görmesi konusunda herhangi bir sorumluluk kabul etmez.</p>
              <p><b>E.8.</b> Kullanıcı, kredi kartı ile ödeme yapmayı tercih ederse, kart bilgilerini sisteme girer ve bu bilgilerin güvenli bir şekilde saklanacağını kabul eder.</p>
              <p><b>E.9.</b> Kullanıcı, kredi kartı ile ödeme yaparken ödeme işleminin başarısız olması durumunda, başka bir ödeme yöntemi veya farklı bir kredi kartı kullanabilir.</p>
              <p><b>E.10.</b> Ways, müşterilerin her çağrıya sürücülerin yanıt vereceğini garanti etmez. Bu, sürücülerin mevcudiyeti, erişilebilirliği ve diğer iş yükleri de dahil olmak üzere çeşitli faktörlere bağlıdır ve herhangi bir çağrıda sürücünün müsait olamayabileceği anlamına gelir.</p>
              <p><b>E.11.</b> Ways, sürücü hizmetleri ile ilgili olarak sağlanan bilgilerin doğruluğu ve güncelliği konusunda herhangi bir sorumluluk kabul etmez. Bu, sürücü hizmetlerinin ayrıntıları, tahmini teslimat süreleri ve benzeri bilgileri içerir.</p>
              <p><b>E.12.</b> Kullanıcı, sipariş sırasında sağladığı teslimat adresinin doğruluğundan ve eksiksiz olmasından tamamen sorumludur. Bu, adres bilgilerinin hatasız ve güncel olmasını ve teslimatın başarıyla gerçekleşebilmesi için gerekli tüm detayların sağlanmasını gerektirir.</p>
              <p><b>E.13.</b> Kullanıcı, platform üzerinden bir gönderi oluşturduğunda, bu işlemle ilgili belirli hakları ve sorumlulukları kabul etmiş sayılır. Bunlar arasında gönderinin içeriği, teslimat süreci ve gönderiyle ilgili herhangi bir ücretlendirme gibi konular yer alır.</p>
              <p><b>E.14.</b> Platform üzerinden yapılan gönderilerle ilgili hizmetler, kullanıcının önceden bilgilendirilmesi koşuluyla ek ücretler içerebilir. Bu, özel teslimat seçenekleri, hızlandırılmış gönderim veya diğer ek hizmetler için geçerli olabilir.</p>
              <h3>TANIM F: SÖZLEŞMENİN KONUSU VE KAPSAMI</h3>
              <p><b>F.1.</b> Bu sözleşme, Ways platformuna üye olan kullanıcıların platformdaki hizmetlerden yararlanma şartlarını ve bu süreçte hem kullanıcıların hem de Ways'nin sahip olduğu hakları ve yerine getirmesi gereken yükümlülükleri ayrıntılı bir şekilde belirler.</p>
              <p><b>F.2.</b> Ways, sürücü hizmetleri almak isteyen kullanıcıları ve sürücüleri bir araya getiren, bu süreci kolaylaştırmak ve optimize etmek için gerekli yazılım desteğini sağlayan dijital bir platform olarak tanımlanır. Bu platform, kullanıcılar ve sürücüler arasındaki etkileşimi ve işlem süreçlerini yönetir.</p>
              <p><b>F.3.</b> Kullanıcı, 4925 sayılı Karayolu Taşıma Kanunu'na göre, Ways'nin kendisi ve sürücüler arasındaki hizmet ilişkisinde resmi bir taraf olmadığını ve bu konuda herhangi bir taahhüt veya sorumluluk üstlenmediğini anlar ve kabul eder. Bu, Ways'nin yalnızca bir aracılık platformu olduğu ve taşıma işlemleriyle doğrudan ilgili olmadığı anlamına gelir.</p>
              <p><b>F.4.</b> Ways'nin iş modeli, Karayolu Taşıma Kanunu çerçevesinde tanımlanan taşımacılık faaliyetlerini içermez. Bu, Ways'nin sadece bir teknoloji platformu sağlayıcısı olduğu ve taşıma işlemlerinin kendisi tarafından gerçekleştirilmediği anlamına gelir.</p>
              <p><b>F.5.</b> Sürücüler, Ways tarafından doğrudan işe alınmış personel, çalışanlar, acenteler veya iş ortakları olarak kabul edilmez. Bunun yerine, bağımsız hizmet sağlayıcılar olarak faaliyet gösterirler. Kullanıcılar, sürücülerle ilgili herhangi bir şikayet veya geri bildirimi Ways'ye iletebilirler, ancak Ways, sürücülerin eylemleri veya davranışlarından dolayı doğrudan sorumlu tutulamaz.</p>
              <h3>TANIM G: DİĞER HÜKÜMLER</h3>
              <p><b>G.1.</b> Bu sözleşme kapsamındaki tüm anlaşmazlıklar ve hukuki işlemler için Türk Hukuku uygulanacak ve yargı yetkisi İstanbul Mahkemelerine ait olacaktır. Bu, sözleşmeyle ilgili her türlü ihtilafın Türkiye'nin yasal çerçevesi içinde ele alınacağı ve İstanbul'daki yargı organlarının bu konularda nihai karar mercii olacağı anlamına gelir.</p>
              <p><b>G.2.</b> Mücbir sebepler (doğal afetler, savaş, isyan gibi olağanüstü durumlar) ve internet bağlantı sorunları gibi kontrol dışı faktörler nedeniyle Ways veya kullanıcıların sözleşmedeki yükümlülüklerini yerine getirememesi durumunda, bu tür sorumluluklar affedilebilir veya geçici olarak askıya alınabilir.</p>
              <p><b>G.3.</b> Herhangi bir hüküm geçersiz veya uygulanamaz olarak tespit edilirse, bu hüküm, uygulanabilir ve geçerli olacak şekilde değiştirilecek veya, eğer bu mümkün değilse, sözleşmeden çıkarılacaktır. Bu değişiklik veya çıkarma işlemi, sözleşmenin diğer hükümlerinin geçerliliğini ve uygulanabilirliğini etkilemeyecektir.</p>
              <p><b>G.4.</b> Gizlilikle ilgili yükümlülükler, sahiplik hakları ve yükümlülüklerle ilgili kısıtlamalar, sözleşmenin sona ermesinin ardından da devam edecektir. Bu, sözleşme süresince kazanılan bilgi ve belgelerin korunmasını ve bu bilgilerin üçüncü taraflara ifşa edilmemesini gerektirecektir.</p>
              <p><b>G.5.</b> Bilgisayar kayıtları, sözleşme ile ilgili herhangi bir ihtilaf durumunda bağlayıcı ve kabul edilebilir delil olarak kullanılacaktır. Bu, sözleşme kapsamında gerçekleştirilen tüm elektronik işlemlerin ve iletişimin kaydedilmesi ve bu kayıtların ihtilafların çözümünde kanıt olarak kullanılabilmesi anlamına gelir.</p>
              <p><b>G.6.</b> Ways, bu sözleşmeyi kendi takdirine bağlı olarak herhangi bir zamanda değiştirebilir. Bu değişiklikler, yapıldıkları tarihten itibaren geçerli olacak ve sözleşmenin ilgili kısımlarına dahil edilecektir. Sözleşme değişiklikleri, taraflara uygun bir yöntemle bildirilecek ve bu değişikliklerin kabulü, sözleşmenin devam eden kullanımı ile teyit edilecektir.</p>
              <p><b>G.7.</b> Bu sözleşme, kullanıcının elektronik olarak onay vermesiyle yürürlüğe girecektir. Kullanıcının elektronik onayı, fiziksel bir imzaya eşdeğer kabul edilecek ve sözleşmenin tüm hüküm ve koşullarının kabul edildiğini gösterecektir. Bu onay süreci, kullanıcının sözleşmeyi tamamen okuduğunu ve anladığını, ve sözleşmedeki hüküm ve koşullarla bağlı olmayı kabul ettiğini teyit eder.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default KullaniciSozlesmesi;
